import React from 'react';

import { NavLink, Link } from "react-router-dom";

// Material
import { PictureAsPdf } from '@material-ui/icons';
// PDF
import PDF from '../covid.pdf';

function importAll(r) {
    return r.keys().map(r);
}
const covidBilder = importAll(require.context('../css/image/covid/', false, /\.(png|jpe?g|svg)$/));
class Kontakt extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        };
    }


    componentDidMount() {
        window.scroll(0, 0);
    }




    render() {
        return (
            <>
                <div className="text-secondary ff-4 animate__animated animate__delay animate__fadeIn" style={{ paddingTop: "150px" }}>
                    <div className="container">
                        <h3 className="border-bottom p-2">COVID-19</h3>
                        <h3 className="text-info mt-5">Wichtige Information</h3>
                        <h5 className="ff-4 ">
                            Einverständniserklärung zur Erhebung personenbezogener Daten Wir sind im Rahmen der Verordnungen des Landes Nordrhein-Westfalens
                            während der aktuellen Corona Pandemie zur Datenerhebung von Kontaktdaten und Aufenthaltszeitpunkte verpflichtet.
                            Die Erhebung Ihrer Daten erfolgt daher auf der Grundlage des Art. 6 Absatz 1 S. 1 lit. c, Abs. 3 Datenschutz-Grundverordnung.
                            Diese Daten dienen ausschließlich der zuständigen Behörde im Bedarfsfall der Kontaktpersonennachverfolgung.
                            Die Daten sind durch uns vier Wochen aufzubewahren und werden anschließend vernichtet.
                        </h5>
                    </div>
                </div>
                <div className="container text-secondary animated zoomInDown ff-4 mt-5 mb-5">
                    <div className="row m-0 mt-5">
                        <Link onClick={() => window.open(PDF, "_blank")} className="col-12">
                            <i>Herunteladen </i>  <PictureAsPdf />
                        </Link>
                        <div className="col-md-6  text-center">

                            <img src={covidBilder[0]} className="border rounded mt-3" width="100%" height="auto" alt="Pireas Logo" />
                        </div>
                        <div className="col-md-6  text-center">
                            <img src={covidBilder[1]} className="border rounded mt-3" width="100%" height="auto" alt="Pireas Logo" />
                        </div>
                        <div className="col-md-6  text-center">
                            <img src={covidBilder[2]} className="border rounded mt-3" width="100%" height="auto" alt="Pireas Logo" />
                        </div>
                        <div className="col-md-6  text-center">
                            <img src={covidBilder[3]} className="border rounded mt-3" width="100%" height="auto" alt="Pireas Logo" />
                        </div>
                        <div className="col-md-12  text-center">
                            <img src={covidBilder[4]} className="border rounded mt-3" width="100%" height="auto" alt="Pireas Logo" />
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default Kontakt;

