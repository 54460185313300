import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

fetch("./config.json")
    .then(res => res.json())
    .then(config => {

        ReactDOM.render(<App config={config} />, document.getElementById('root'));
    })
    .catch(console.error);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
