import React from "react";
import { Link } from "react-router-dom";
import { Drawer } from '@material-ui/core';
import Icons from './icon';

class Cookies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    getCockiInfo() {
        let storageDate = localStorage.getItem("cockicAcceptDate");
        let coocieAcceptDay = Number(new Date(storageDate || "").getDay());
        let aktuellDay = Number(new Date().getDay());

        if (!coocieAcceptDay || (aktuellDay - coocieAcceptDay) > 6) {
            return <Drawer variant="persistent" open={this.state.open} elevation={20} anchor="bottom" className="ff-4 shadow-sm">
                <h5 className="text-center text-secondary pt-3 pb-3">Diese Website verwendet Cookies. Durch die Nutzung dieser Webseite erklären Sie sich damit einverstanden, dass Cookies gesetzt werden.
                    <Link to="/datenschutz"><span className="text-dark"> Zum Datenschutz </span></Link> </h5>
                <button className="p-1 border-0 bg-success pointer text-white" onClick={() => this.setCockieDate()}>Okay <Icons icon="Check" /></button>
            </Drawer>
        }
        return "";
    }

    setCockieDate() {
        localStorage.setItem("cockicAcceptDate", new Date())
        this.setState({ open: false })
    }

    render() {
        return this.getCockiInfo();
    }
}

export default Cookies;