import React from 'react';

// Router
import { withRouter } from "react-router-dom";
import lieferungBild from "../css/image/lieferung.jpg";
import party from "../css/image/party.jpg";
import gutschein from "../css/image/gutschein.jpg";
import dart from "../css/image/dart.jpg";
import test from "../css/image/test.jpg";

// Komponent


import Icon from '../components/icon';

/* function importAll(r) {
    return r.keys().map(r);
}
const serviceBilder = importAll(require.context('../css/image/service/', false, /\.(png|jpe?g|svg)$/)); */
class Service extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        };
    }


    componentDidMount() {
        window.scroll(0, 0);
    }



    render() {
        return (<div className="animate__animated animate__delay animate__fadeIn" /* */>


            <div className=" hertDiv2 pl-0 pr-0" style={{ paddingTop: "150px" }} >

                <div className="container text-secondary ff-4 ">
                    <h3 className="border-bottom p-2 text-white">Service</h3>
                    <div className="row m-0 text-center mt-5">
                        <div className="col-12 col-md-3 mt-3">
                            <div class="card ">
                                <img src={lieferungBild} alt="Avatar" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                                <div class="container">
                                    <h4 className="mt-3"><b>Lieferung</b></h4>
                                    <p>- Nur per Anruf - Lieferung Extertal, Umgebung Rinteln - Schnelle Lieferung</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mt-3">
                            <div class="card">
                                <img src={party} alt="Avatar" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                                <div class="container">
                                    <h4 className="mt-3"><b>Partyservice</b></h4>
                                    <p>-Catering -Buffet -Kalte Vorspeise -Warme Hauptspeise</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mt-3">
                            <div class="card">
                                <img src={gutschein} alt="Avatar" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                                <div class="container">
                                    <h4 className="mt-3"><b>Gutschein</b></h4>
                                    <p>Geschenkgutscheine kommen immer gut an. Vor Ort abholen oder wir Liefern es </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mt-3">
                            <div class="card">
                                <img src={dart} alt="Avatar" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                                <div class="container">
                                    <h4 className="mt-3"><b>Dart</b></h4>
                                    <p>- Dartautomaten GAUSELMANN -Dartverein Anmeldung -Dart - Liga</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className=" pt-5 text-secondary ff-4  pl-0 pr-0">
                <div className="container">
                    <div className="row m-0">
                        <div className="col-md-6 mt-2">
                            <img src={test} alt="" style={{ width: "100%" }} className="rounded" />
                        </div>
                        <div className="col-md-6 d-flex mt-3">
                            <div className="m-auto ">
                                <h3>Familienfeier, Taufe, Trauerfeier, Jubiläum in Pireas</h3>
                                <p>Ihre Familienfeier sollte für Sie und Ihre Gäste ein unvergessliches Erlebnis sein. Bei der Planung Ihrer Feier stehen wir Ihnen mit fachkompetenter Beratung zur Seite.</p>
                                <p className="text-center"><Icon icon="FaRegSmileWink" style={{ fontSize: "100pt", color: "#00c4ff" }} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className=" pt-5 text-secondary ff-4 hertDiv pl-0 pr-0 ">
                <div className="container">
                    <div className="row m-0">

                        <div className="col-12 bg-white  ">
                            <h3>Firmenfeier und Firmenevent in Pireas</h3>
                            <p>
                                Bei der Planung Ihres Sommerfestes, Ihrer Weihnachtsfeier oder einem Essen mit Ihren Geschäftsfreunden oder Kollegen
                                stehen wir Ihnen hilfreich zur Seite. Gerne beraten wir Sie bei der Zusammenstellung Ihres Menüs oder Buffets.
                                Eine für jeden Anlass und nach Ihren Wünschen gestaltete Dekoration ist für uns selbstverständlich.
                                Für Ihr Sommerevent reservieren wir für Sie sowohl im Haus als auch im bedienten Bereich unseres Biergartens, sodass Sie jederzeit nach Wetterlage entscheiden können,
                                wo Sie Ihr Event feiern möchten. Bei Ihrer Weihnachtsfeier erwartet Sie bei uns ein festlich geschmücktes Haus. Unsere Räume sind ebenfalls weihnachtlich dekoriert.
                                Planen Sie Ihre Weihnachtsfeier doch mit einem Glühweinempfang und selbstgemachten Plätzchen oder empfangen Sie Ihre Gäste mit einem Aperetif in weihnachtlicher Atmosphäre.
                            </p>
                        </div>
                    </div>
                </div>

            </div>






        </div>



        );
    }

}

export default withRouter(Service);

