import React from 'react';
import { NavLink, Link } from "react-router-dom";
import { AppBar, IconButton, Toolbar, Drawer } from '@material-ui/core';
import logo from "../css/image/logo.png";
import Icon from './icon';
import flyer from '../flyer.pdf';




class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    render() {


        if (!this.props.mobileVersion) {
            return (<>
                <AppBar className="appBar" position="absolute">
                    <Toolbar className="d-flex">

                        <Link to="/" >
                            <img width="90px" height="auto" src={logo} className=" " alt="" />
                        </Link>

                        <Link className="ml-auto nav-item" activeClassName="active" to="#" style={{ color: "rgb(245, 0, 87)" }} onClick={() => window.open("tel:052629943483", "_self")}>
                            <p className=""><Icon icon="FiPhoneCall" style={{ color: "inherit" }} /> 05262 9943483</p>
                        </Link>

                        <Link className="nav-item" activeClassName="active" to="#">
                            <p onClick={() => window.open(flyer)} className="">FLYER</p>
                        </Link>

                        <NavLink className="nav-item" activeClassName="active" to="/covid">
                            <p className="">COVID-19</p>
                        </NavLink>
                        <NavLink className="nav-item" activeClassName="active" to="/service">
                            <p className="">SERVICE</p>
                        </NavLink>
                        <NavLink className="nav-item mr-5" activeClassName="active" to="/contact"  >
                            <p className="">KONTAKT</p>
                        </NavLink>
                    </Toolbar>
                </AppBar>
            </>);
        }

        return <>
            <AppBar className="appBar" position="absolute">
                <Toolbar className="d-flex">
                    <Link to="/">
                        <img width="90px" height="auto" src={logo} className=" " alt="" />
                    </Link>

                    <IconButton className="ml-auto" onClick={() => this.setState({ open: !this.state.open })}>
                        <Icon icon="Menu" style={{ fontSize: "45pt", color: "white" }} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer className=" ff-4" open={this.state.open} anchor="right" onClose={() => this.setState({ open: false })}  >

                <div style={{ backgroundColor: "rgb(56, 56, 56)" }}>
                    <Link activeClassName="active" to="/">
                        <img width="150px" height="auto" src={logo} className=" " alt="" />
                    </Link>
                </div>

                <div className="mt-3 pl-3 border-bottom overflow-hidden">
                    <NavLink onClick={() => this.setState({ open: false }, () => window.open(flyer))} className="d-flex mt-3 nav-item-m" activeClassName="active" to="/flyer">
                        <Icon icon="RiPagesLine" style={{ fontSize: "22pt", marginRight: "10px" }} />   <p>FLYER</p>
                    </NavLink>
                    <NavLink onClick={() => this.setState({ open: false })} className="d-flex  nav-item-m" activeClassName="active" to="/covid">
                        <Icon icon="BsInfoCircle" style={{ fontSize: "22pt", marginRight: "10px" }} />  <p>COVID-19</p>
                    </NavLink>
                    <NavLink onClick={() => this.setState({ open: false })} className="d-flex  nav-item-m" activeClassName="active" to="/service">
                        <Icon icon="RiServiceFill" style={{ fontSize: "22pt", marginRight: "10px" }} />  <p>SERVICE</p>
                    </NavLink>
                    <NavLink onClick={() => this.setState({ open: false })} className="d-flex  nav-item-m" activeClassName="active" to="/contact" >
                        <Icon icon="RiCustomerService2Line" style={{ fontSize: "22pt", marginRight: "10px" }} />   <p>KONTAKT</p>
                    </NavLink>
                </div>

                <Link className="mt-3 pl-3 d-flex  nav-item-m" activeClassName="active" onClick={() => window.open("tel:052629943483", "_self")} >
                    <Icon icon="FiPhoneCall" style={{ fontSize: "22pt", marginRight: "10px" }} />  <p>05262 9943483</p>
                </Link>
            </Drawer>
        </>;

    }
}

export default Navigation;