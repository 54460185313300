import React from 'react';

import bild1 from "../css/image/1.jpg";
import tricko from "../css/image/tricko.jpg";
import ipad from "../css/image/ipad.png";
import Speisekarte from '../Speisekarte.pdf';

import Icon from "../components/icon";

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        };
    }


    componentDidMount() {
        window.scroll(0, 0);
    }



    render() {

        return (<div className="animate__animated animate__delay animate__fadeIn mb-5">
            <div className="d-flex">
                <img src={bild1} alt="" className="startBild cover" />
            </div>

            <div className="container text-center " style={{ marginTop: "-300px" }}>
                <h1 className="willkommText d-flex flex-column ff-1">
                    <span>WELCOME</span>
                    <span>THE ORIGINAL GREEK</span>
                </h1>
            </div>


            <div className="container mt-5">
                <div className="row m-0 ">
                    <div className="col-12 col-md-6 text-center d-flex flex-column  ff-2" style={{ color: "#7b7b7b" }}>
                        <div className="mt-auto">
                            <h3 className="bg-white rounded pt-3 ">Geschenkgutscheine kommen immer gut an. </h3>
                            <p className=" mt-3">Ob zu Geburtstagen, Hochzeiten, Valentinstag oder Weihnachten. Mit der Geschenk-Gutschein hast du für jeden Anlass ein tolles persönliches Geschenk.</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 text-center">
                        <img src={ipad} alt="" width="100%" height="auto" />
                    </div>
                </div>
            </div>


            <div className="hertDiv mt-5" /* style={{ marginTop: "150px" }} */>
                <div className="row m-0 mt-5 ff-1">
                    <div className="col-12 text-center   p-1" style={{ color: "#7b7b7b" }}>
                        <h1 className="m-auto" style={{ textShadow: "-2px 1px 2px #696969" }}>FROM GREECE TO GERMANY</h1>
                        <div className="herz mt-3"><Icon icon="FaHeart" style={{ fontSize: "100pt", marginTop: "-20px", color: "#f50057" }} /></div>
                    </div>
                    <div className="col-12 text-center d-flex flex-column " style={{ color: "#7b7b7b" }}>
                        <h4 style={{ fontSize: "10pt", color: "white" }}>VON UNS FÜR EUCH</h4>
                        <button onClick={() => window.open(Speisekarte)} className="speiseButton"><h3>Speisekarte</h3></button>
                    </div>

                </div>
            </div>


            <div className="hertDiv2" style={{ marginTop: "" }}>
                <div className="container">
                    <h3 className="ff-2 text-white" style={{ borderBottom: "3px solid #aaaaaa" }}>
                        What they're saying about us
                    </h3>
                    <div className="row m-0 mt-5">


                        <div className="col-12 col-md-3 mt-1 d-flex flex-column  z-1000">
                            <div className=" border rounded-sm shadow p-2 bg-white pb-5 text-secondary" style={{ zIndex: "-1" }}>
                                <i>„Sehr gutes Essen <span role="img">👍</span> Das Fleisch war perfekt gebraten, die Sauce war unglaublich lecker. Und die Wandgemälde sind echt schön.“</i>
                            </div>
                            <div className="border bg-dark text-center rounded-circle d-flex shadow ml-auto mr-auto" style={{ width: "60px", height: "60px", marginTop: "-30px", zIndex: "1000", fontSize: "22pt" }}>
                                <p className="m-auto p-0 text-white">BS</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-1 d-flex flex-column  z-1000">
                            <div className=" border rounded-sm shadow p-2 bg-white pb-5 text-secondary" style={{ zIndex: "-1" }}>
                                <i>„Sehr gut organisiert, dadurch selten lange Wartezeiten beim Abholen der Speisen. Falls doch, wird es vorher angesagt. Das Lokal ansich ist nett gestaltet und ist sehr sauber.“</i>
                            </div>
                            <div className="border bg-dark text-center rounded-circle d-flex shadow ml-auto mr-auto" style={{ width: "60px", height: "60px", marginTop: "-30px", zIndex: "1000", fontSize: "22pt" }}>
                                <p className="m-auto p-0 text-white">MN</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-1 d-flex flex-column  z-1000">
                            <div className=" border rounded-sm shadow p-2 bg-white pb-5 text-secondary" style={{ zIndex: "-1" }}>
                                <i>„Immer wieder gerne. Sehr guter Service. Und das wichtigste das Essen schmeckt sehr gut“</i>
                            </div>
                            <div className="border bg-dark text-center rounded-circle d-flex shadow ml-auto mr-auto" style={{ width: "60px", height: "60px", marginTop: "-30px", zIndex: "1000", fontSize: "22pt" }}>
                                <p className="m-auto p-0 text-white">EG</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-1 d-flex flex-column  z-1000">
                            <div className=" border rounded-sm shadow p-2 bg-white pb-5 text-secondary" style={{ zIndex: "-1" }}>
                                <i>„Freundlich geführtes Restaurant mit griechischen Spezialitäten. Sehr Kinderfreundlich. Sonderwünsche sind kein Problem. Abholen geht i.d. Regel sehr schnell. Geschmacklich sind die Speisen wirklich gut. Wir sind bisher immer zufrieden gewesen.“</i>
                            </div>
                            <div className="border bg-dark text-center rounded-circle d-flex shadow ml-auto mr-auto" style={{ width: "60px", height: "60px", marginTop: "-30px", zIndex: "1000", fontSize: "22pt" }}>
                                <p className="m-auto p-0 text-white">BK</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className="indexBildDiv" style={{ marginTop: "150px" }}>
                <div className="container text-secondary" >
                    <h3 className="ff-4 row m-0" style={{ borderBottom: "3px solid #aaaaaa" }}>
                        <span className="col-12 col-md-6">Öffnungszeiten</span>
                    </h3>

                    <div className="mt-3 ff-4 row text-center">

                        <p className="col-12 col-md-4 pl-2"> MI - SA 17:00 - 21:00 Uhr</p>
                        <p className="col-12 col-md-4 pl-2"> Sonntag: 12:00 - 14:00 Uhr 17:00 - 21:00 Uhr</p>
                        <p className="col-12 col-md-4 pl-2"> MONTAG & DIENSTAG RUHETAG</p>


                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default Index;