import React from 'react';
import './css/app.css';
import './css/animate.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Material
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';

// Seiten
import Index from "./seiten/index";
import Impressum from "./seiten/impressum";
import Covid from "./seiten/covid";
import Service from "./seiten/service";
import Kontakt from "./seiten/kontakt";
import Datenschutz from "./seiten/datenschutz";

// Components
import Navigation from "./components/navigation"
import Footer from "./components/footer.js"
import Cookies from './components/cookies';

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      mobileVersion: false,
      open: false
    };
  }

  componentDidMount() {
    window.onresize = (e) => {
      if (e.target.innerWidth < 1000) {
        if (this.state.mobileVersion === true) {
          return;
        }
        this.setState({ mobileVersion: true });
      } else {
        if (this.state.mobileVersion === false) {
          return;
        }
        this.setState({ mobileVersion: false });
      }
    };

    if (window.innerWidth < 1000) {
      this.setState({ mobileVersion: true })
    }
  }


  render() {
    return (<>


      <Dialog open={this.state.open}>
        <DialogTitle>Wichtige Information</DialogTitle>
        <DialogContent>

          <p>Liebe Gäste, wir machen vom 17.07.2023 bis zum 16.08.2023 <mark>Urlaub</mark>. </p>
          <p>Ab dem 17.08.2023 sind wir wieder für Sie da.</p>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ open: false })} color="secondary" variant="contained">
            Alles klar <Check />
          </Button>
        </DialogActions>
      </Dialog>

      <Router>
        <Navigation mobileVersion={this.state.mobileVersion} />
        <Switch>


          <Route path="/datenschutz">
            <Datenschutz />
          </Route>

          <Route path="/contact">
            <Kontakt />
          </Route>

          <Route path="/service">
            <Service />
          </Route>

          <Route path="/covid">
            <Covid />
          </Route>

          <Route path="/impressum">
            <Impressum />
          </Route>

          <Route path="/">
            <Index />
          </Route>


        </Switch>
        <Footer />

        <Cookies />
      </Router >
    </>);
  }

}

export default App;