import React from 'react';
import { NavLink, Link } from "react-router-dom";
import { AppBar, IconButton, Toolbar, Drawer, Divider, } from '@material-ui/core';

import Icon from './icon';
import flyer from '../flyer.pdf';


import logo from "../css/image/logo2.png";

const insta = "https://www.instagram.com/explore/locations/239790616528944/";


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            nachObenStyle: { display: "none" }
        }
    }


    componentDidMount() {
        window.onscroll = () => {
            if (window.scrollY > 300) {
                if (this.state.nachObenStyle.display === "inherit") {
                    return;
                }
                this.setState({ nachObenStyle: { display: "inherit" } });
            } else {

                if (this.state.nachObenStyle.display === "none") {
                    return;
                }
                this.setState({ nachObenStyle: { display: "none" } });
            }
        }



    }








    render() {


        return (<>


            <button
                className="nachOben animate__animated animate__fadeInDown"
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                style={this.state.nachObenStyle}
            >
                <Icon icon="ArrowUpward" style={{ color: "inherit" }} />
            </button>




            <div style={{ backgroundColor: "rgb(56, 56, 56)" }}>
                <div className="container text-white p-3 pt-5 pb-5 " >
                    <div className="row m-0">
                        <div className="col-12 text-center">
                            <img src={logo} alt="" width="100%" height="auto" style={{ maxWidth: "500px", marginTop: "10px" }} />
                        </div>

                        <div className="col-12 row m-0 ff-4 text-center mt-5">
                            <Link to="/covid" className="col-6 col-md-2 footer-item">COVID-19</Link>
                            <Link to="/flyer" className="col-6 col-md-2 footer-item" onClick={() => window.open(flyer)}>FLYER</Link>
                            <Link to="/service" className="col-6 col-md-2 footer-item">SERVICE</Link>
                            <Link to="/contact" className="col-6 col-md-2 footer-item">KONTAKT</Link>
                            <Link to="/impressum" className="col-6 col-md-2 footer-item">IMPRESSUM</Link>
                            <Link to="/datenschutz" className="col-6 col-md-2 footer-item">DATENSCHUTZ</Link>
                        </div>

                        <div className="col-12 d-flex mt-3">
                            <p className="m-2 footer-item ml-auto" onClick={() => window.open("https://www.facebook.com/Pireasalmena")}><Icon icon="Facebook" style={{ fontSize: "30pt", color: "#4675bc" }} /></p>
                            <p className="m-2 footer-item " onClick={() => window.open(insta)}><Icon icon="Instagram" style={{ fontSize: "30pt", color: "#f61f84" }} /></p>
                            <p className="m-2 footer-item mr-auto" onClick={() => window.open("https://wa.me/+491716152702")}><Icon icon="FaWhatsapp" style={{ fontSize: "30pt", color: "#4dc85a" }} /></p>

                        </div>

                        <div className="col-12 d-flex flex-column mt-3 ff-4">
                            <p className="m-auto text-nowrap p-0"><Icon icon="Map" style={{ color: "white" }} /> Hauptstraße 27, 32699 Extertal</p>
                            <p className="m-auto"><Icon icon="Phone" style={{ color: "white" }} /> 05262 9943483</p>
                        </div>

                        <div className="col-12 mt-3 pl-5 pr-5">
                            <hr style={{ backgroundColor: "white", width: "100%" }} />
                        </div>

                        <div className="col-12 d-flex ff-4 small untenText">
                            <p className="m-auto"> © 2021 www.pireass.de Alle Angaben ohne Gewähr</p>
                        </div>
                        <div className="col-12 d-flex ff-4 small untenText mt-1">
                            <p className="m-auto"> CREATED BY <span className="text-warning">Arman Khachatryan</span></p>
                        </div>


                    </div>
                </div>
            </div>



        </>);




    }
}

export default Navigation;